import { useEffect, useState } from "react";
import { useData } from "./hooks";
import { ChartDataPoint, DatabaseProperty, DatabaseQuery, XAxisType } from "./types";
import { Chart, registerables } from 'chart.js';
import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';

Chart.register(...registerables);

const PROXY = 'https://cors-anywhere.remyhidra.dev/';

const DEFAULT_DATA = {
  id: '1',
  name: 'Chart',
  data: {
    darkMode: false,
    accessToken: '',
    databaseId: '',

    // Notion column ID
    xPropId: '',

    // Each set of data, with attribute id, legend label, and display info
    yPropData: [] as {
      id: string, // Notion column ID
      label: string,
      borderColor: string,
      displayPoints: boolean,
      pointBackgroundColor: string,
      pointBorderColor: string,
      pointHoverBackgroundColor: string,
      pointHoverBorderColor: string,
    }[],

    displayLegend: true,
    yForceZero: true,
    spanGaps: true,
  },
};



// const DEFAULT_DATA = {
//   id: '1',
//   name: 'Chart',
//   data: {
//     darkMode: true,
//     accessToken: 'secret_1NU6WUguYzwgrt6DobofEoq1ZpAXCpNNbUuC1uJI9Ph',
//     databaseId: '7fd19a3004194b1caf422abbf85c3433',

//     xPropId: 'UmCL',

//     // Each set of data, with attribute id, legend label, and display info
//     yPropData: [{
//       id: 'kRi%5B',
//       label: 'Value',
//       borderColor: "rgba(212, 76, 71, 1)",
//       displayPoints: true,
//       pointBackgroundColor: "rgba(212, 76, 71, .3)",
//       pointBorderColor: "rgba(212, 76, 71, .5)",
//       pointHoverBackgroundColor: "rgba(212, 76, 71, .8)",
//       pointHoverBorderColor: "rgba(212, 76, 71, 1)",
//     }, {
//       id: 'Z%40Uy',
//       label: 'Value 2',
//       borderColor: "rgba(51, 126, 169, 1)",
//       displayPoints: false,
//       pointBackgroundColor: "rgba(51, 126, 169, .3)",
//       pointBorderColor: "rgba(51, 126, 169, .5)",
//       pointHoverBackgroundColor: "rgba(51, 126, 169, .8)",
//       pointHoverBorderColor: "rgba(51, 126, 169, 1)",
//     }],

//     displayLegend: true,
//     yForceZero: true,
//     spanGaps: true,
//   },
// };

/** 
 * COLORS
 *                Light                       Dark
 * Default  rgb(55, 53, 47)         rgba(255, 255, 255, 0.9)
 * Red      rgba(212, 76, 71, 1)    rgba(234, 135, 140, 1)
 * Blue     rgba(51, 126, 169, 1)   rgba(102, 170, 218, 1)
 * Green    rgba(68, 131, 97, 1)    rgba(113, 178, 131, 1)
 * Orange   rgba(217, 115, 13, 1)   rgba(217, 133, 56, 1)
 * Purple   rgba(144, 101, 176, 1)  rgba(176, 152, 217, 1)
 * Pink     rgba(193, 76, 138, 1)   rgba(176, 152, 217, 1)
 * Brown    rgba(159, 107, 83, 1)   rgba(212, 150, 117, 1)
 * Gray     rgba(120, 119, 116, 1)  rgba(159, 164, 169, 1)
 * Yellow   rgba(203, 145, 47, 1)   rgba(201, 145, 38, 1)
 * 
 * BACKGROUND COLORS
 *                Light                       Dark
 * Default  
 * Red      rgba(253, 235, 236, 1)  rgba(94, 52, 54, 1)
 * Blue     rgba(231, 243, 248, 1)  rgba(94, 52, 54, 1)
 * Green    rgba(237, 243, 236, 1)  rgba(46, 68, 58, 1)
 * Orange   rgba(251, 236, 221, 1)  rgba(85, 59, 41, 1)
 * Purple   rgba(244, 240, 247, .8) rgba(69, 58, 91, 1)
 * Pink     rgba(249, 238, 243, .8) rgba(81, 56, 77, 1)
 * Brown    rgba(244, 238, 238, 1)  rgba(76, 61, 53, 1)
 * Gray     rgba(241, 241, 239, 1)  rgba(60, 65, 68, 1)
 * Yellow   rgba(251, 243, 219, 1)  rgba(79, 64, 41, 1)
 */

function property2Value(prop: DatabaseProperty): number|string|DateTime|undefined {
  switch (prop.type) {
    case 'number':
      return prop.number;

    case 'date':
      return prop.date ? DateTime.fromISO(prop.date.start) : undefined;

    case 'rich_text':
      return prop.rich_text ? prop.rich_text.reduce((s, cur) => s + cur.plain_text, '') : undefined;

    case 'title':
      return prop.title ? prop.title.reduce((s, cur) => s + cur.plain_text, '') : undefined;

    default:
      console.error(`Error: Type "${(prop as any).type}" not supported`);
      return undefined;
  }
}

function App() {
  const [, data, isPreview] = useData(DEFAULT_DATA.name, DEFAULT_DATA.data);
  const [queryResult, setQueryResult] = useState<DatabaseQuery>();
  const [chartData, setChartData] = useState<ChartDataPoint[]>([]);
  const [xAxisType, setXAxisType] = useState<XAxisType>(XAxisType.DATE);
  const [isLoading, setIsLoading] = useState(false);
  const [unknownError, setUnknownError] = useState(false);


  const chartEl = document.getElementById('chart') as HTMLCanvasElement | null;

  // Fetch the DB data to show in the graph
  useEffect(() => { (async () => {
    if (!data.databaseId || !data.accessToken || !data.xPropId || unknownError) { return; }

    try {
      const query = await fetch(`${PROXY}https://api.notion.com/v1/databases/${data.databaseId}/query`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'Authorization': `Bearer ${data.accessToken}`,
          'Notion-Version': '2022-02-22',
        },
      }).then(r => r.json()) as DatabaseQuery;

      if (query.results.length === 0) {
        setQueryResult(undefined);
      } else {
        setQueryResult(query);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setUnknownError(true);
    }
  })()}, [data.accessToken, data.databaseId, data.xPropId, unknownError]);

  // Format the data in chart data points
  useEffect(() => { (async () => {
    if (!queryResult || queryResult.results.length === 0 || unknownError || !data.yPropData || data.yPropData.length === 0 || data.yPropData.every(p => !p.id)) { return; }

    setIsLoading(true);
    try {
      // Find the props names
      const [xPropName, xProp] = Object.entries(queryResult.results[0].properties).find(([, prop]) => prop.id === data.xPropId) ?? ['', null];
      const yPropIds = data.yPropData.map(e => e.id);
      const yPropNames = Object.entries(queryResult.results[0].properties).filter(([, prop]) => yPropIds.includes(prop.id)).map(e => e[0]);

      // Create the chart data object
      const dataPts: ChartDataPoint[] = queryResult.results.map<ChartDataPoint|undefined>(row => {
        // Handle null column values here
        const x = property2Value(row.properties[xPropName]);
        const ys = yPropNames.map(name => [row.properties[name].id, property2Value(row.properties[name])])
                             .filter(([, value]) => value)
                             .reduce((o, [id, value]) => ({...o, [id as string]: value}), {});

        // Remove the row if we can't place it on the chart
        if (!x || Object.entries(ys).length === 0) { return undefined; }
        return { x,...ys };
      }).filter(row => row) as ChartDataPoint[];

      // Setup the X axis type and sort the data points
      switch(xProp?.type) {
        case 'number':
          setXAxisType(XAxisType.NUMBER);
          dataPts.sort();
          break;
        case 'date':
          setXAxisType(XAxisType.DATE);
          dataPts.sort((a, b) => (a.x as DateTime).toMillis() - (b.x as DateTime).toMillis());
          break;
        default:
          setXAxisType(XAxisType.CATEGORY);
      }
    
      setChartData(dataPts);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setUnknownError(true);
      setIsLoading(false);
    }
  })()}, [queryResult, data.yPropData, data.xPropId, unknownError]);
  
  // Draw the chart
  useEffect(() => {
    if (!chartEl || chartData.length === 0 || !data.yPropData || data.yPropData.length === 0 || unknownError) { return; }

    try {
      setIsLoading(true);
      const textColor = data.darkMode ? 'hsl(0, 0%, 100%, .55)' : 'hsla(0, 0%, 0%, .55)';
      const gridLinesColor = data.darkMode ? 'hsla(0, 0%, 100%, .1)' : 'hsla(0, 0%, 0%, .1)';

      const chart = new Chart(chartEl, {
        type: 'line',
        data: {
          datasets: data.yPropData.map(prop => ({
            label: prop.label,
            data: chartData,
            parsing: { yAxisKey: prop.id },
            borderWidth: 2,
            pointHoverRadius: 7,
            borderColor: prop.borderColor,
            backgroundColor: 'rgba(0, 0, 0, 0)',

            pointBackgroundColor: prop.displayPoints ? prop.pointBackgroundColor : 'rgba(0,0,0,0)',
            pointBorderColor: prop.displayPoints ? prop.pointBorderColor : 'rgba(0,0,0,0)',
            pointHoverBackgroundColor: prop.displayPoints ? prop.pointHoverBackgroundColor : 'rgba(0,0,0,0)',
            pointHoverBorderColor: prop.displayPoints ? prop.pointHoverBorderColor : 'rgba(0,0,0,0)',
          })),
        },

        options: {
          spanGaps: data.spanGaps,
          responsive: true,
          maintainAspectRatio: false,
          animation: false,//isPreview ? false : undefined, // Disable animations in preview
          color: textColor,
          plugins: {
            legend: { display: data.displayLegend },
          },
          scales: {
            x: {
              type: ({
                [XAxisType.NUMBER]: 'linear',
                [XAxisType.DATE]: 'time',
                [XAxisType.CATEGORY]: 'category',
              })[xAxisType] as any,
              ticks: { color: textColor },
              grid: { color: gridLinesColor },
            },
            y: {
              beginAtZero: data.yForceZero,
              ticks: { color: textColor },
              grid: { color: gridLinesColor },
            }
          }
        }
      });

      setIsLoading(false);

      return () => chart.destroy();
    } catch (e) {
      console.error(e);
      setUnknownError(true);
    }
  }, [chartEl, chartData, xAxisType, data.yPropData, data.displayLegend, data.yForceZero, data.spanGaps, unknownError, isPreview]);

  const hasConfigError = (!data.accessToken || !data.databaseId || !data.xPropId || !data.yPropData || data.yPropData.length === 0 || data.yPropData.some(p => !p.id)) && !isLoading;
  const hasEmptyDB = chartData.length === 0 && !isLoading;

  return (
    <div className={`
      embed-root 
      ${data.darkMode ? 'dark' : ''}
    `}>

      <div className={`wrapper`}>
        <canvas id="chart"/>

        {unknownError &&
          <div className="no-data">An unknown problem happened :(</div>
        }

        {!unknownError && !hasConfigError && !hasEmptyDB && isLoading &&
          <div className="no-data">Loading...</div>
        }

        {!unknownError && hasConfigError &&
          <div className="no-data">The widget is not configured correctly</div>
        }

        {!unknownError && !hasConfigError && hasEmptyDB &&
          <div className="no-data">The database is empty</div>
        }
      </div>

    </div>
  );
}

export default App;
