import { DateTime } from "luxon";

export type DatabaseQuery = {
  results: Array<{ 
    properties: {
      [key: string]: DatabaseProperty, 
    }, 
  }>,
};

export type DatabaseProperty = NumberProperty | DateProperty | RichTextProperty | TitleProperty;

type TitleProperty = {
  id: 'title',
  type: 'title',
  title: TextElement[],
};

type NumberProperty = {
  id: string,
  type: 'number',
  number: number,
};

type DateProperty = {
  id: string,
  type: 'date',
  date: {
    start: string,
    end: null,
    time_zone: null,
  },
};

type RichTextProperty = {
  id: string,
  type: 'rich_text',
  rich_text: (TextElement | EquationElement)[]
}

type TextElement = {
  type: 'text',
  text: {
    content: string,
    link: { url: string } | null,
  },
  annotations: TextAnnotations,
  plain_text: string,
  href: string|null,
}

type EquationElement = {
  type: 'equation',
  equation: { expression: string },
  annotations: TextAnnotations,
  plain_text: string,
  href: string|null,
}

type TextAnnotations = {
  bold: boolean,
  italic: boolean,
  strikethrough: boolean,
  underline: boolean,
  code: boolean,
  color: string,
}

// Data point
// x can be of the following types :
// - number : linear x Axis and the points are sorted
// - date : linear x Axis with date labels and the points are sorted
// - string : no sorting, the points are displayed in the default order, with the string label
export type ChartDataPoint = {
  [key: string]: number|any,
  x: number|string|DateTime,
};

export enum XAxisType {
  NUMBER, DATE, CATEGORY,
}